import en from "./translations/en";
import ru from "./translations/ru";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'ru',
  messages: {
    ru,
    en,
  }
}))